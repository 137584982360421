import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./style.css";
import { useState } from "react";

export default function Footer() {
    const [showModal, setShowModal] = useState(false);

    return (
        <div className="top-shadow-sm mt-5 mb-3">
            <Modal
                centered
                show={showModal}
                // fullscreen="sm-down"
                onHide={() => setShowModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>კორპორაციული შეთავაზება</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        შესთავაზეთ თქვენს მომხმარებლებს ან თანამშრომლებს ქარაფის
                        ფასდაკლებული მომსახურება, როგორც ბენეფიტი.
                    </p>
                    <p>მეტი ინფორმაციისთვის დაგვიკავშირდით: info@carup.ge</p>
                </Modal.Body>
            </Modal>
            <Container>
                <DesktopFooter setShowModal={setShowModal} />
                <MobileFooter setShowModal={setShowModal} />
            </Container>
        </div>
    );
}

function DesktopFooter({ setShowModal }) {
    const { t } = useTranslation();

    return (
        <Row className="d-none d-sm-none d-md-flex">
            <Col className="mt-3" sm={5}>
                <div className="mb-2 social-icons-container">
                    <Link
                        to="https://facebook.com/carup.ge"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            className="social-icons"
                            src="/images/facebook.svg"
                            alt="Facebook logo"
                        />
                    </Link>
                    <Link
                        to="https://m.me/101178736342121"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            className="social-icons"
                            src="/images/messenger.svg"
                            alt="Messenger logo"
                        />
                    </Link>
                    <Link
                        to="https://www.youtube.com/@CARUP_OFFICIAL"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            className="social-icons"
                            src="/images/youtube.svg"
                            alt="Youtube logo"
                        />
                    </Link>
                </div>
                <p
                    className="mb-1"
                    style={{
                        fontSize: "18px",
                        color: "var(--carup-primary-blue)",
                    }}
                >
                    <Link
                        to="tel:574180111"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        📞 574 180 111
                    </Link>
                </p>
                <p
                    className="mb-1"
                    style={{
                        fontSize: "18px",
                        color: "var(--carup-primary-blue)",
                    }}
                >
                    <Link
                        to="mailto:info@carup.ge"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        📬 info@carup.ge
                    </Link>
                </p>
            </Col>
            <Col
                className="mt-3"
                xs={12}
                md={7}
                style={{
                    textAlign: "right",
                }}
            >
                <p
                    className="mb-1"
                    style={{
                        fontSize: "18px",
                    }}
                >
                    ყოველდღე 10:00 - 19:00
                </p>
                <p
                    className="mb-1"
                    style={{
                        fontSize: "18px",
                    }}
                >
                    <Link to="#" onClick={() => setShowModal(true)}>
                        კორპორაციული შეთავაზება
                    </Link>
                </p>
                <p
                    className="mb-1"
                    style={{
                        fontSize: "18px",
                    }}
                >
                    <Link
                        to="/terms-and-conditions.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t("terms-and-conditions")}
                    </Link>
                </p>
                <p
                    style={{
                        fontSize: "18px",
                    }}
                >
                    <Link to="/privacy-policy">{t("privacy-policy")}</Link>
                </p>
            </Col>
        </Row>
    );
}

function MobileFooter({ setShowModal }) {
    const { t } = useTranslation();

    return (
        <Row className="d-flex d-md-none text-center">
            <Col className="mt-3">
                <div className="mb-2 social-icons-mobile-container">
                    <Link
                        to="https://facebook.com/carup.ge"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            className="social-icons"
                            src="/images/facebook.svg"
                            alt="Facebook logo"
                        />
                    </Link>
                    <Link
                        to="https://m.me/101178736342121"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            className="social-icons"
                            src="/images/messenger.svg"
                            alt="Messenger logo"
                        />
                    </Link>
                    <Link
                        to="https://www.youtube.com/@CARUP_OFFICIAL"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            className="social-icons"
                            src="/images/youtube.svg"
                            alt="Youtube logo"
                        />
                    </Link>
                </div>
                <p
                    className="mb-1"
                    style={{
                        fontSize: "18px",
                        color: "var(--carup-primary-blue)",
                    }}
                >
                    <Link
                        to="tel:574180111"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        📞 574 180 111
                    </Link>
                </p>
                <p
                    style={{
                        fontSize: "18px",
                        color: "var(--carup-primary-blue)",
                    }}
                >
                    <Link
                        to="mailto:info@carup.ge"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        📬 info@carup.ge
                    </Link>
                </p>
                <hr
                    style={{
                        margin: "unset",
                    }}
                />
                <p
                    className="mb-1 mt-3"
                    style={{
                        fontSize: "18px",
                    }}
                >
                    ყოველდღე 10:00 - 19:00
                </p>
                <p
                    className="mb-1"
                    style={{
                        fontSize: "18px",
                    }}
                >
                    <Link to="#" onClick={() => setShowModal(true)}>
                        კორპორაციული შეთავაზება
                    </Link>
                </p>
                <p
                    className="mb-1"
                    style={{
                        fontSize: "18px",
                    }}
                >
                    <Link
                        to="/terms-and-conditions.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t("terms-and-conditions")}
                    </Link>
                </p>
                <p
                    style={{
                        fontSize: "18px",
                    }}
                >
                    <Link to="/privacy-policy">{t("privacy-policy")}</Link>
                </p>
            </Col>
        </Row>
    );
}
